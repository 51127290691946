<template>
  <div class="create-content-main">
    <div v-if="loading" class="loader"></div>
    <div class="create-slag-form">
      <h2>コンテンツエディタ</h2>
      <form
        class="p-4"
        v-on:submit.prevent="updateContent()"
        id="create-content"
        autocomplete="off"
      >
        <fieldset :disabled="loading">
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイトル <span class="required">必須</span></label
            >
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="title"
              placeholder="タイトル"
            />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >カテゴリ <span class="required">必須</span></label
            >
            <div class="col-sm-12 row mb-3 pr-0">
              <b-form-select
                class="col-sm-10"
                v-model="category_id"
                tag-placeholder="Add this as new category"
                :options="listCategoryOptions"
              ></b-form-select>
              <div class="custom-group-btn ml-auto">
                <b-button variant="success" v-b-modal.modal-add-category
                  ><b-icon class="sidebar-icon" icon="plus" font-scale="1.5"
                /></b-button>
              </div>
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >著者名 <span class="required">必須</span></label
            >
            <div class="col-sm-12 row mb-3 pr-0">
              <b-form-select
                class="col-sm-10"
                id="select-auth"
                v-model="author_id"
                tag-placeholder="Add this as author"
                :options="listAuthorOptions"
              ></b-form-select>
              <div class="custom-group-btn ml-auto">
                <b-button variant="success" v-on:click="returnCreateAuthor()"
                  >著者を作成する</b-button
                >
              </div>
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >金額（円）<span class="required">必須</span></label
            >
            <input
              class="form-input col-sm-12"
              type="number"
              v-model="amount"
              :maxlength="10"
              :min="0"
              placeholder="0"
              oninvalid="this.setCustomValidity('番号を入力してください')"
              oninput="this.setCustomValidity('')"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >先行販売金額（円） <span class="required">必須</span></label
            >
            <input
              class="form-input col-sm-12"
              type="number"
              v-model="pre_sale_amount"
              :maxlength="10"
              :min="0"
              placeholder="0"
              oninvalid="this.setCustomValidity('番号を入力してください')"
              oninput="this.setCustomValidity('')"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >コンテンツ概要文</label
            >
            <textarea
              class="form-input col-sm-12"
              type="text"
              v-model="description"
              placeholder="コンテンツ概要文"
            />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >サムネイル画像</label
            >
            <div class="col-sm-12 row mb-3 img-custome">
              <div class="col-sm-12 pl-0">
                <label for="fileInput" class="btn label-input-file btn-success"
                  >アップロード</label
                >
                <div class="row">
                  <div class="col-sm-12">
                    <input
                      id="fileInput"
                      class="d-none"
                      @change="onChangeImage($event)"
                      accept="image/jpg, image/png, image/jpeg"
                      type="file"
                    />
                    <div
                      style="
                        width: 500px;
                        height: 500px;
                        border: 1px solid #000;
                      "
                      v-if="urlImage"
                    >
                      <img
                        v-if="urlImage"
                        :src="urlImage"
                        width="100%"
                        height="100%"
                        style="object-fit: contain"
                        class="img-preview"
                      />
                    </div>
                    <div
                      style="
                        width: 500px;
                        height: 500px;
                        border: 1px solid #000;
                      "
                      v-if="imagePre"
                    >
                      <img
                        class="preview-img"
                        width="100%"
                        height="100%"
                        style="object-fit: contain"
                        :src="imagePre"
                        alt=""
                      />
                    </div>
                    <div
                      style="
                        width: 500px;
                        height: 500px;
                        border: 1px solid #000;
                      "
                      v-if="checkImg && avatar"
                    >
                      <img
                        class="preview-img-check"
                        width="100%"
                        height="100%"
                        style="object-fit: contain"
                        :src="avatar"
                        alt=""
                      />
                    </div>
                    <!-- <image-uploader
                      :preview="true"
                      :className="[
                        'fileinput',
                        { 'fileinput--loaded': hasImage },
                      ]"
                      capture="environment"
                      :debug="1"
                      doNotResize="gif"
                      :autoRotate="true"
                      outputFormat="file"
                      :maxWidth="500"
                      :maxHeight="500"
                      accept="image/jpg, image/png, image/jpeg"
                      @input="setImage"
                    >
                    </image-uploader> -->
                    <!-- <p v-if="imagePre">
                      <img class="preview-img" :src="imagePre" alt="" />
                    </p>
                    <p v-if="checkImg">
                      <img class="preview-img-check" :src="avatar" alt="" />
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >販売方法 <span class="required">必須</span></label
            >
            <b-form-group class="col-sm-12 pl-0" v-slot="{ ariaDescribedby }">
              <div class="d-flex">
                <b-form-radio
                  v-model="content_type"
                  class="mr-3"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="0"
                  >通常販売</b-form-radio
                >
                <b-form-radio
                  v-model="content_type"
                  class="mx-3"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="1"
                  >先行販売</b-form-radio
                >
                <b-form-radio
                  v-model="content_type"
                  class="mx-3"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="2"
                  >投票</b-form-radio
                >
              </div>
            </b-form-group>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >ショップ公開設定 <span class="required">必須</span></label
            >
            <b-form-group class="col-sm-12 pl-0" v-slot="{ ariaDescribedby }">
              <div class="d-flex">
                <b-form-radio
                  v-model="is_public"
                  class="mr-3"
                  :aria-describedby="ariaDescribedby"
                  name="public-radios"
                  :value="0"
                  >非公開</b-form-radio
                >
                <b-form-radio
                  v-model="is_public"
                  class="mx-3"
                  :aria-describedby="ariaDescribedby"
                  name="public-radios"
                  :value="1"
                  >公開</b-form-radio
                >
              </div>
            </b-form-group>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >【購入】付与スラグ</label
            >
            <div
              class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="slag_type_1"
                  tag-placeholder="購入付与スラグを追加してください"
                  placeholder="購入付与スラグを追加してください"
                  label="slag_name_en"
                  track-by="slag_name_en"
                  :options="listSlagOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  :allow-empty="false"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >【閲覧】付与スラグ</label
            >
            <div
              class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="slag_type_2"
                  tag-placeholder="閲覧付与スラグを追加してください"
                  placeholder="閲覧付与スラグを追加してください"
                  label="slag_name_en"
                  track-by="slag_name_en"
                  :options="listSlagOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  @tag="addTag"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >【投票】付与スラグ</label
            >
            <div
              class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="slag_type_3"
                  tag-placeholder="投票付与スラグを追加してください"
                  placeholder="投票付与スラグを追加してください"
                  label="slag_name_en"
                  track-by="slag_name_en"
                  :options="listSlagOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              type="submit"
              :disabled="isLoading"
              variant="success"
              class="mr-4"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              更新する</b-button
            >
            <b-button
              variant="secondary"
              class="mr-4 back-btn"
              v-on:click="returnList()"
              >戻る</b-button
            >
          </div>
        </fieldset>
      </form>
    </div>

    <!-- Modal Add Category -->
    <b-modal
      id="modal-add-category"
      size="lg"
      title="カテゴリを作成"
      hide-footer
      @show="resetModalCategory"
      @hidden="resetModalCategory"
    >
      <CRow
        ><CCol sm="12"
          ><div class="d-flex flex-column">
            <CInput label="カテゴリ名" v-model="category_name" /></div
        ></CCol>
        <CCol sm="12"
          ><div class="d-flex flex-column">
            <CInput label="カテゴリID" v-model="category_code" /></div
        ></CCol>
        <CCol sm="12">
          <div class="d-flex justify-content-center">
            <b-button
              v-on:click.prevent="createCategory()"
              variant="success"
              class="mr-4"
              >作成</b-button
            >
            <b-button
              variant="secondary"
              @click="$bvModal.hide('modal-add-category')"
              class="mx-2"
              >引ける</b-button
            >
          </div>
        </CCol>
      </CRow>
    </b-modal>
    <!-- Modal Add Category -->

    <!-- Modal Add Author -->
    <b-modal
      id="modal-add-author"
      size="lg"
      title="著者を作成する"
      hide-footer
      class="create-content-modal"
      @show="resetModalAuthor"
      @hidden="resetModalAuthor"
    >
      <form class="p-4" v-on:submit.prevent="createAuthor()">
        <b-form-group
          id="input-group-8"
          label="ファーストネーム"
          label-for="input-8"
        >
          <b-form-input id="input-7" v-model="first_name"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-8" label="苗字" label-for="input-8">
          <b-form-input id="input-7" v-model="last_name"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-8" label="電子メイル" label-for="input-8">
          <b-form-input id="input-7" v-model="email"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-8" label="説明" label-for="input-8">
          <textarea id="input-7" v-model="descriptionAuthor" />
        </b-form-group>
        <b-form-group
          id="input-group-8"
          label="アバター"
          label-for="input-8"
          class="d-flex flex-column"
        >
          <label
            for="avtInput"
            class="btn label-input-file btn-success"
            @click="avtInput"
            >アップロード</label
          >
          <span v-if="nameFile" class="ml-2">{{ nameFile }}</span>
          <b-form-file
            class="avtInput"
            v-model="avatar"
            plain
            accept="image/jpg, image/png, image/jpeg"
            @change="previewAvtInput"
          ></b-form-file>
        </b-form-group>

        <div class="d-flex justify-content-center">
          <b-button type="submit" variant="success" class="mr-4">作成</b-button>
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-add-author')"
            class="mx-2"
            >引ける</b-button
          >
        </div>
      </form>
    </b-modal>

    <!-- Modal Add Author -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ImageUploader from "vue-image-upload-resize";
import { Constants } from "../../utils/constants";
// import { Api } from "../../utils/http-common";
export default {
  name: "EditContent",
  components: {
    // ImageUploader,
  },
  data() {
    return {
      content: localStorage.getItem("content")
        ? JSON.parse(localStorage.getItem("content"))[0]
        : "",
      title: "",
      category_id: "",
      author_id: "",
      description: "",
      amount: "",
      pre_sale_amount: "",
      content_type: "",
      is_public: "",
      discount: "",
      advertisement_id: 1,
      end_date: "",
      isLoading: false,
      optionCategory: [],
      viewBtnEditCover: false,
      title_cover: "",
      slag_type_1: [],
      slag_type_2: [],
      slag_type_3: [],
      display_condition: false,
      page_id: "",
      position_page: "",
      page_content: "",
      description_slag: "",
      slag_name_en: "",
      url: "",
      description_chapter: "",
      position_chapter: "",
      title_chapter: "",
      cover: {},
      hasImage: false,
      image: null,
      listPageAdded: [],
      page: [],
      listPageOptions: [],
      listSlagOptions: [],
      listCategoryOptions: [],
      listAuthorOptions: [],
      chapter: [],
      page_title: "",
      viewBtnAddCover: true,
      listContentOptions: [],
      previewImage: null,
      category_name: "",
      category_code: "",
      first_name: "",
      last_name: "",
      email: "",
      descriptionAuthor: "",
      avatar: null,
      imagePre: null,
      filename: "",
      nameFile: "",
      checkImg: false,
      isCreateCat: false,
      urlImage: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    const reqData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    this.getContentById(reqData);
    const request = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(request);
    this.getListCategoriesInShop(request);
    this.getListAuthorInShop(request);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  watch: {
    contentById() {
      if (this.content) {
        this.title = this.content.title;
        this.description = this.content.description;
        this.amount = this.content.amount;
        this.pre_sale_amount = this.content.pre_sale_amount;
        this.category_id = this.content.category_id;
        this.author_id = this.content.author_id;
        this.content_type = this.content.content_type;
        this.is_public = this.content.is_public;
        this.slag_type_1 = this.content.slag_type_1;
        this.slag_type_2 = this.content.slag_type_2;
        this.slag_type_3 = this.content.slag_type_3;
        this.checkImg = true;
        this.avatar = this.content.avatar
          ? this.content.avatar
          : this.content.imagePre;
        this.filename = this.content.filename;
      } else {
        this.title = this.contentById.title;
        this.description = this.contentById.description
          ? this.contentById.description
          : "";
        this.amount = this.contentById.amount;
        this.pre_sale_amount = this.contentById.pre_sale_amount;
        this.content_type = this.contentById.content_type;
        this.is_public = this.contentById.is_public;
        this.category_id = this.contentById.category_id;
        this.author_id = this.contentById.user_id;
        this.imagePre =
          process.env.VUE_APP_ROOT_BACKEND +
          "/storage/" +
          this.contentById.image_url;

        var slags = this.contentById.slags;
        slags.forEach((value) => {
          if (value.type == 1) {
            this.slag_type_1.push(value);
          } else if (value.type == 2) {
            this.slag_type_2.push(value);
          } else {
            this.slag_type_3.push(value);
          }
        });
      }
    },
    listCategoriesInShop() {
      this.listCategoryOptions = this.listCategoriesInShop.map((item) => ({
        text:
          item.category_name.length > Constants.MAX_LENGTH
            ? item.category_name.substr(0, Constants.MAX_LENGTH) + "..."
            : item.category_name,
        value: item.id.toString(),
      }));
      if (this.isCreateCat) {
        this.category_id =
          this.listCategoriesInShop && this.listCategoriesInShop[0]
            ? this.listCategoriesInShop[0].id
            : "";
        this.isCreateCat = false;
      }
    },
    //Slag
    slag_id() {},
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
    //Author
    listAuthor() {
      if (this.listAuthor.length > 0) {
        this.listAuthorOptions = this.listAuthor.map((item) => ({
          text:
            item && item.name && item.name.length > Constants.MAX_LENGTH
              ? item.name.substr(0, Constants.MAX_LENGTH) + "..."
              : item.name,
          value: item.id.toString(),
        }));
      } else {
        this.listAuthorOptions = [
          { text: "リストは空です", value: null, disabled: true },
        ];
      }
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    loading() {},
  },
  computed: {
    ...mapGetters([
      "contentById",
      "listCategoriesInShop",
      "success",
      "message",
      "error",
      "listSlag",
      "listAuthor",
      "loading",
    ]),
  },
  methods: {
    ...mapActions({ getListCategoriesInShop: "getListCategoriesInShop" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ CreateSlag: "CreateSlag" }),
    ...mapActions({ updateContentById: "updateContentById" }),
    ...mapActions({ getListAuthorInShop: "getListAuthorInShop" }),
    ...mapActions({ CreateCategory: "CreateCategory" }),
    ...mapActions({ CreateAuthor: "CreateAuthor" }),
    ...mapActions({ getContentById: "getContentById" }),

    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    //createCategory
    async createCategory() {
      const formUpdate = {
        category_name: this.category_name,
        category_id: this.category_code,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch(
        "CreateCategory",
        formUpdate
      );
      if (dataReturn.success) {
        const request = {
          shop_id: this.shop_id,
        };
        this.getListCategoriesInShop(request);
        this.$nextTick(() => {
          this.category_name = "";
          this.category_code = "";
          this.$bvModal.hide("modal-add-category");
        });
        this.isCreateCat = true;
      } else {
        this.isLoading = false;
      }
    },

    //create author
    async createAuthor() {
      if (this.first_name.trim() === "") {
        this.$toasted.error("名フィールドは必須です");
      } else if (this.email.trim() === "") {
        this.$toasted.error("メールフィールドは必須です。");
      } else if (this.descriptionAuthor.trim() === "") {
        this.$toasted.error("説明フィールドは必須です。");
      } else if (this.avatar === "") {
        this.$toasted.error("アバターが必要です。");
      } else {
        var formData = new FormData();
        formData.append("shop_id", this.shop_id);
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("email", this.email);
        formData.append("description", this.descriptionAuthor);
        formData.append("avatar", this.avatar);
        this.$store.commit("set", ["loading", true]);
        const dataReturn = await this.$store.dispatch("CreateAuthor", formData);
        if (dataReturn.success) {
          this.getListAuthorInShop();
          if (!this.$store.loading) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-author");
            });
          }
        }
      }
    },

    setImage: function (output) {
      this.checkImg = false;
      this.hasImage = true;
      this.imagePre = null;
      this.filename = output.name;
      this.avatar = output;
    },

    async updateContent() {
      const idContent = this.$route.params.id;
      this.isLoading = true;
      let shop_id = this.shop_id;

      let slags = [];
      this.slag_type_1.forEach((value) => {
        var slag = {
          slag_id: value.id ? value.id : value.slag_id,
          type: 1,
        };
        slags.push(slag);
      });
      this.slag_type_2.forEach((value) => {
        var slag = {
          slag_id: value.id ? value.id : value.slag_id,
          type: 2,
        };
        slags.push(slag);
      });
      this.slag_type_3.forEach((value) => {
        var slag = {
          slag_id: value.id ? value.id : value.slag_id,
          type: 3,
        };
        slags.push(slag);
      });
      if (this.filename) {
        var imgPreView = this.checkImg
          ? document.getElementsByClassName("preview-img-check")
          : document.getElementsByClassName("img-preview");
        var dataurl = imgPreView[0].src;
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        this.image = new File([u8arr], this.filename, { type: mime });
      }
      var formData = new FormData();
      formData.append("id", idContent);
      formData.append("shop_id", shop_id);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("amount", this.amount);
      formData.append("pre_sale_amount", this.pre_sale_amount);
      formData.append("category_id", this.category_id);
      formData.append("author_id", this.author_id);
      formData.append("content_type", this.content_type);
      formData.append("is_public", this.is_public);
      if (this.image) {
        formData.append("image", this.image);
      }
      for (var i = 0; i < slags.length; i++) {
        formData.append("slags[" + i + "][slag_id]", slags[i]["slag_id"]);
        formData.append("slags[" + i + "][type]", slags[i]["type"]);
      }
      const dataReturn = await this.$store.dispatch(
        "updateContentById",
        formData
      );
      if (dataReturn.success) {
        localStorage.removeItem(Constants.CONTENT);
        this.$router.go(-1);
      } else {
        this.isLoading = false;
      }
    },

    resetModalCategory() {
      this.category_name = "";
      this.category_code = "";
    },
    resetModalAuthor() {
      this.name = "";
      this.email = "";
      this.descriptionAuthor = "";
      this.avatar = "";
      this.nameFile = "";
    },
    avtInput() {
      document.getElementsByClassName("avtInput")[0].click();
    },
    previewAvtInput() {
      var avtImg = document.getElementsByClassName("avtInput")[0].files[0];
      this.nameFile = avtImg.name;
    },
    returnList() {
      localStorage.removeItem(Constants.CONTENT);
      this.$router.go(-1);
    },
    returnCreateAuthor() {
      const content = [];
      if (this.filename) {
        var imgPreView = this.checkImg
          ? document.getElementsByClassName("preview-img")
          : document.getElementsByClassName("img-preview");
        this.avatar = imgPreView[0].src;
      }
      content.push({
        shop_id: this.shop_id,
        title: this.title,
        description: this.description,
        amount: this.amount,
        pre_sale_amount: this.pre_sale_amount,
        category_id: this.category_id,
        author_id: this.author_id,
        content_type: this.content_type,
        is_public: this.is_public,
        slag_type_1: this.slag_type_1,
        slag_type_2: this.slag_type_2,
        slag_type_3: this.slag_type_3,
        avatar: this.avatar,
        filename: this.filename,
        imagePre: this.imagePre,
      });
      localStorage.setItem(Constants.CONTENT, JSON.stringify(content));
      this.$router.push({
        name: this.$route.params.shopId
          ? "CreateAuthor"
          : "CreateAuthor domain",
      });
    },
    getBase64(file) {
      // Returns a promise which gets resolved or rejected based on the reader events
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // Sets up even listeners BEFORE you call reader.readAsDataURL
        reader.onload = function () {
          const result = reader.result;
          return resolve(result);
        };
        reader.onerror = function (error) {
          return reject(error);
        };
        // Calls reader function
        reader.readAsDataURL(file);
      });
    },
    async onChangeImage(e) {
      this.filename = e.target.files[0].name;
      this.urlImage = await this.getBase64(e.target.files[0]);
      this.checkImg = false;
      this.avatar = await this.getBase64(e.target.files[0]);
      this.checkImg = false;
      this.hasImage = true;
      this.imagePre = null;
    },
  },
};
</script>
